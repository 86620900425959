import React, { useEffect, useState } from "react";
import axios from "axios";
import { serverLink } from "../../../resources/url";
import { connect } from "react-redux/es/exports";
import Loader from "../../common/loader/loader";
import {toast} from "react-toastify";
import PageHeader from "../../common/pageheader/pageheader";
import {currencyConverter, formatDate, formatDateAndTime} from "../../../resources/constants";
import Modal from "../../common/modal/modal";
import ReportTable from "../../common/table/report_table";
import swal from "sweetalert";
import Select from "react-select";

function FinanceMyBudget(props) {
    const token = props.LoginDetails[0].token;
    const [isLoading, setIsLoading] = useState(true);
    const columns = ["S/N", "Year", "Amount", "Allocated Amount", "Balance Amount", "Status", "Added Date", "Decision By", "Decision Date", "Action"];
    const [dataTable, setDataTable] = useState([]);
    const [yearList, setYearList] = useState([]);
    const [budgetList, setBudgetList] = useState([]);
    const [budgetItemList, setBudgetItemList] = useState([]);
    const [budgetTrackerList, setBudgetTrackerList] = useState([]);
    const formInitialize = {entry_id:'', department_code: props.LoginDetails[0].DepartmentCode, year_id:'', amount:0, allocated_amount:0, balance_amount:0, status:'submitted', inserted_by: props.LoginDetails[0].StaffID}
    const [selectedBudget, setSelectedBudget] = useState(formInitialize);
    const selectedItemInitialize = {entry_id:'', budget_id:'', item_name:'', item_description:'', quantity:'',amount:'',total:'',inserted_by: props.LoginDetails[0].StaffID}
    const [selectedItem, setSelectedItem] = useState([]);

    const getBudgetYear = (year_list, year_id) => {
        const list = year_list.filter(r=>r.EntryID === year_id);
        return list.length > 0 ? `${formatDateAndTime(list[0].StartDate, 'date')} to ${formatDateAndTime(list[0].EndDate, 'date')}` : 'No Date'
    }

    const getData = async () => {
        await axios.get(`${serverLink}staff/finance/finance-and-budget/my-budget-data/${props.LoginDetails[0].StaffID}`, token)
            .then((result) => {
                if (result.data.message === 'success') {
                    const year_list = result.data.year;
                    const budget_list = result.data.data;
                    const tracker_list = result.data.tracker
                    const budget_items = result.data.item;
                    setYearList(year_list)
                    setBudgetList(budget_list);
                    setBudgetTrackerList(tracker_list);
                    setBudgetItemList(budget_items);

                    if (budget_list.length > 0) {
                        let rows = [];
                        budget_list.map((item, index) => {
                            rows.push([
                                index+1, getBudgetYear(year_list, item.YearID), currencyConverter(item.Amount), currencyConverter(item.AllocatedAmount), currencyConverter(item.BalanceAmount), item.Status, formatDateAndTime(item.InsertedDate, 'date'), item.DecisionBy, currencyConverter(item.DecisionDate),
                                currencyConverter(item.Amount), item.InsertedBy, formatDateAndTime(item.InsertedDate, 'date'),
                                <div className="btn-group">
                                    <button className="btn btn-primary btn-sm"
                                            onClick={()=>{
                                                swal({
                                                    title: "Are you sure?",
                                                    text: "Once deleted, you will not be able to recover the transaction!",
                                                    icon: "warning",
                                                    buttons: true,
                                                    dangerMode: true,
                                                }).then((willDelete) => {
                                                    if (willDelete) {
                                                        handleDelete(item.EntryID);
                                                    }
                                                });
                                            }}
                                    ><i className="fa fa-trash" /></button>
                                </div>
                            ]);
                        });
                        setDataTable(rows)
                    }
                }
                setIsLoading(false);
            })
            .catch((err) => {
                toast.error("NETWORK ERROR")
            });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (selectedBudget.year_id === '') {toast.error("Please select budget year");return false;}
        if (selectedItem.length === 0) {toast.error("Enter the budget item(s)");return false;}

        const sendData = {
            ...selectedBudget,
            items: selectedItem,
        }
        console.log(sendData)
        toast.info("Submitting...");
        return false;

        if (selectedBudget.entry_id === '') {
            await axios.post(`${serverLink}staff/finance/finance-and-budget/budget`, sendData, token)
                .then(res => {
                    if (res.data.message === 'success') {
                        toast.success("Budget Added Successfully");
                        setSelectedBudget(formInitialize);
                        setSelectedItem([]);
                        document.getElementById("closeModal").click();
                        getData();
                    } else {
                        toast.error(res.data.message)
                    }
                })
                .catch((err) => {
                    toast.error("NETWORK ERROR")
                })
        } else {
            await axios.patch(`${serverLink}staff/finance/finance-and-budget/budget`, sendData, token)
                .then(res => {
                    if (res.data.message === 'success') {
                        toast.success("Budget Updated Successfully");
                        getData();
                        document.getElementById("closeModal").click();
                    } else {
                        toast.error(res.data.message)
                    }
                })
                .catch((err) => {
                    toast.error("NETWORK ERROR")
                })
        }
    }

    const handleDelete = async (item, index) => {
        const filter_selected_item = selectedItem[index];
        let items = [];
        selectedItem.map((r,i) => {
            if (i!==index) {
                items.push(r)
            }
        })
        setSelectedItem(items);

        if (filter_selected_item.entry_id !== '') {
            toast.info("Deleting...")
            await axios.delete(`${serverLink}staff/finance/finance-and-budget/budget-item/${item.entry_id}/${item.total}`, token)
                .then(res => {
                    if (res.data.message === 'success') {
                        toast.success("Budget Item Deleted Deleted Successfully");
                        getData();
                    } else {
                        toast.error(res.data.message)
                    }
                })
                .catch((err) => {
                    toast.error("NETWORK ERROR")
                })
        }
    }

    const onAddBudgetItem = () => {
        setSelectedItem([...selectedItem,selectedItemInitialize])
    }
    const handleItemChange = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        const index = id.split("-")[2];
        const selected_item = selectedItem[parseInt(index)]

        const item_record = selectedItem;
        item_record.map((r,i) => {
            if (i === parseInt(index)) {
                if (id.includes("item-name")) {
                    r.item_name = value;
                } else if (id.includes("item-description")) {
                    r.item_description = value;
                } else {
                    r.quantity = value;
                }
            }
        });
        console.log(item_record)
        setSelectedItem([...item_record])

    }

    useEffect(() => {
        getData()
    }, []);

    return isLoading ? (
            <Loader />
        ) :
        (
            <>
                <Modal large title={selectedBudget.entry_id === '' ? 'Add New Budget' : 'Update Budget'}>
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <div className="form-group">
                                <label htmlFor="year_id">Budget Year</label>
                                <select id="year_id" className="form-control" value={selectedBudget.year_id} onChange={(e)=>setSelectedBudget({...selectedBudget, [e.target.id]: e.target.value})}>
                                    <option value="">Select Financial Year</option>
                                    {
                                        yearList.length > 0 && yearList.map((r,i) => {
                                            return <option key={i} value={r.EntryID}>{`${formatDateAndTime(r.StartDate, 'date')} to ${formatDateAndTime(r.EndDate, 'date')}`}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <h3 className="mb-3">Budget Items</h3>
                        {
                            selectedItem.length > 0 && selectedItem.map((r,i) => {
                                return (
                                    <div key={i} className="row mb-3">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label htmlFor={`item-name-${i}`}>Item {i+1} Name</label>
                                                <input type="text" className="form-control" id={`item-name-${i}`} value={r.item_name} onChange={handleItemChange}/>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label htmlFor={`item-description-${i}`}>Item {i+1} Description</label>
                                                <input type="text" className="form-control" id={`item-description-${i}`} value={r.item_description} onChange={handleItemChange}/>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label htmlFor={`item-amount-${i}`}>Item {i+1} Amount</label>
                                                <input type="number" className="form-control" id={`item-amount-${i}`} value={r.quantity} onChange={handleItemChange}/>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label htmlFor={`item-quantity-${i}`}>Item {i+1} Quantity</label>
                                                <input type="number" className="form-control" id={`item-quantity-${i}`} value={r.quantity} onChange={handleItemChange}/>
                                            </div>
                                        </div>
                                        <div className="col-md-1">
                                            <button className="btn btn-danger btn-sm mt-8" onClick={()=>handleDelete(r, i)}><i className="fa fa-trash" /></button>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                    <button className="btn btn-primary w-75" onClick={handleSubmit} disabled={selectedItem.length===0}>Submit</button>
                    <button type="button" className="btn btn-info w-25" onClick={onAddBudgetItem}>Add Item</button>
                    {
                        selectedItem.length > 0 &&
                            <div>
                                {
                                    selectedItem.map((r,i) => {

                                    })
                                }
                            </div>
                    }
                </Modal>

                <div className="card" style={{ borderStyle: 'none', borderWidth: '0px', width:'100%' }}>
                    <div className="">
                        <PageHeader
                            title={"MY BUDGET"}
                            items={["Human-Resources", "Finance & Budget", "My Budget"]}
                        />
                        <button className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#kt_modal_general"  onClick={()=>{ setSelectedBudget(formInitialize); setSelectedItem([]) }}>Add New Budget <i className="fa fa-plus" /></button>
                        <div className="row col-md-12" style={{width:'100%'}}>
                            <ReportTable
                                title={`My Budget List`}
                                columns={columns}
                                data={dataTable}
                                height={"600px"}
                            />
                        </div>
                    </div>

                </div>
            </>
        )
}


const mapStateToProps = (state) => {
    return {
        LoginDetails: state.LoginDetails,
    };
};
export default connect(mapStateToProps, null)(FinanceMyBudget);
