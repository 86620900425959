import React, {useEffect, useState} from "react";
import PageHeader from "../../../common/pageheader/pageheader";
import axios from "axios";
import {serverLink} from "../../../../resources/url";
import Loader from "../../../common/loader/loader";
import {showAlert} from "../../../common/sweetalert/sweetalert";
import {toast} from "react-toastify";
import {connect} from "react-redux";
import Select2 from "react-select2-wrapper";
import ReportTable from "../../../common/table/report_table";

function MarkExamBarcode(props) {
    const token = props.loginData[0].token
    const [isLoading, setIsLoading] = useState(false);
    const [venue, setVenue] = useState([]);
    const [data, setData] = useState([]);
    const columns = ["S/N", "Student ID", "Student Name", "Module Code", "Module Name", "Attendance", "Exam Hall", "Seat No", "Mark Attendance"];
    const [formData, setFormData] = useState({
        VenueID: "",
        VenueID2: "",
        Date: "",
        Start: "",
        End: "",
        InsertedBy: props.loginData[0].StaffID
    })

    const getExamData = async () => {
        await axios
            .get(`${serverLink}staff/timetable/exam/data`, token)
            .then((response) => {
                let rows = [];
                response.data.Venue.length > 0 &&
                response.data.Venue.map((row) => {
                    rows.push({text: row.VenueName, id: row.EntryID});
                });
                setVenue(rows);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log("NETWORK ERROR");
            });
    };

    const getAttendanceData = async () => {
        toast.info("Please wait...");
        await axios.get(`${serverLink}staff/timetable/exam/barcode/data/${formData.VenueID}/${formData.Date}/${formData.Start}/${formData.End}`, token)
            .then((response) => {
                let rows = [];
                response.data.length > 0 &&
                response.data.map((row, index) => {
                    rows.push([(index + 1), row.StudentID, row.StudentName, row.ModuleCode, row.ModuleName, row.IsPresent == 1 ? "Attended" : "Not Attended", row.HallName, row.SeatNo, (
                        row.IsPresent === 1 ? <input type="checkbox" checked disabled /> : <input type="checkbox" checked={!true} onChange={(e)=>onMarkAttendance(row, e)} />
                    )]);
                });
                setData(rows);
                setIsLoading(false);
                if (response.data.length === 0){
                    toast.error("No Record Found");
                }
            })
            .catch((err) => {
                toast.error("NETWORK ERROR");
                console.log("NETWORK ERROR");
            });
    };

    const onEdit = async (e) => {
        console.log(e.target.value)
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    }

    const onMarkAttendance = async (data, e) => {
        console.log(data)
        let sendData = { ...formData, BarcodeID: data.EntryID }
        await axios.patch(`${serverLink}staff/timetable/exam/barcode/mark-attendance`, sendData, token).then((result) => {
            if (result.data.message === "success") {
                toast.success("Exam Attendance Marked Successfully");
                getAttendanceData();
            } else {
                showAlert(
                    "ERROR",
                    "Something went wrong. Please try again!",
                    "error"
                );
            }

        }).catch((err) => {
            console.log("NETWORK ERROR");
        });

    }


    useEffect(() => {
        getExamData().then((r) => {
        });
    }, []);



    useEffect(() => {
        if (formData.VenueID !== "" && formData.Date !== "" && formData.Start !== "" && formData.End !== ""){
            getAttendanceData();
        }
    }, [formData.VenueID]);

    useEffect(() => {
        if (formData.VenueID !== "" && formData.Date !== "" && formData.Start !== "" && formData.End !== ""){
            getAttendanceData();
        }
    }, [formData.Date]);

    useEffect(() => {
        if (formData.VenueID !== "" && formData.Date !== "" && formData.Start !== "" && formData.End !== ""){
            getAttendanceData();
        }
    }, [formData.Start]);

    useEffect(() => {
        if (formData.VenueID !== "" && formData.Date !== "" && formData.Start !== "" && formData.End !== ""){
            getAttendanceData();
        }
    }, [formData.End]);

    return isLoading ? (
        <Loader/>
    ) : (
        <div className="d-flex flex-column flex-row-fluid">
            <PageHeader
                title={"Mark Exam Barcode Attendance"}
                items={["Assessment", "Exam & Record", "Mark Exam Barcode Attendance"]}
            />
            <div className="row">
                <div className="row pt-5">
                    <div className="col-lg-3 col-md-4 pt-5">
                        <label htmlFor="ModuleCode">Select Exam Venue</label>
                        <Select2
                            id="VenueID"
                            name="VenueID"
                            value={formData.VenueID}
                            data={venue}
                            onChange={onEdit}
                            options={{placeholder: "Search Venue",}}
                        />
                    </div>

                    <div className="col-lg-3 col-md-4 pt-5">
                        <div className="form-group">
                            <label htmlFor="SettingsID">Select Exam Date</label>
                            <input type="date" id="Date" className="form-control" name="Date" value={formData.Date} onChange={onEdit}/>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-4 pt-5">
                        <label htmlFor="ModuleCode">Select Exam Start Time</label>
                        <select
                            id="Start"
                            name="Start"
                            value={formData.Start}
                            className="form-control"
                            onChange={onEdit}
                        >
                            <option value="">Select Option</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                    </div>
                    <div className="col-lg-3 col-md-4 pt-5">
                        <label htmlFor="ModuleCode">Select Exam End Time</label>
                        <select
                            id="End"
                            name="End"
                            value={formData.End}
                            className="form-control"
                            onChange={onEdit}
                        >
                            <option value="">Select Option</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>
                    </div>
                </div>
            </div>

            {isLoading ? (
                <Loader/>
            ) : (
                <div className="table-responsive pt-10">
                    <ReportTable columns={columns} data={data}/>
                </div>

            )}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        loginData: state.LoginDetails,
        currentSemester: state.currentSemester,
    };
};

export default connect(mapStateToProps, null)(MarkExamBarcode);
